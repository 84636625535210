<template>
    <div v-if="this.$global.settingMenuShow && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected" class="campaign-client-filter-update container__input__campaign__management">
        <label>Sort By Client</label>
        <div class="select-campaign-wrapper">
            <select v-model="selectedGroupCompany" v-on:change="onGroupChange($event)">
                <option v-for="(item, index) in this.$global.selectsGroupCompany.companyGroupList" :key="index"
                    :value="item.id">{{ item.group_name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'select-client-dropdown',
    data() {
        return {
            selectedGroupCompany: '',
        }
    },
    methods: {
        onGroupChange(event) {
            //alert(this.$global.selectsGroupCompany.companyGroupSelected);
            localStorage.setItem('companyGroupSelected', event.target.value);
            this.$router.go();
        },
    },
    mounted() {
        this.selectedGroupCompany = '';
        if (localStorage.getItem('companyGroupSelected') != null) {
            this.selectedGroupCompany = localStorage.getItem('companyGroupSelected');
        }
    }
}
</script>

<style></style>